<template>
  <div class="px-2">
    <div class="p-2 telcolor">
      <van-cell
        title="订单客服:13107873675"
        is-link
        label="周一至周五9:00-18:00 周六周日9:00-17:00"
        url="tel:13107873675"
      />
    </div>
    <van-divider
      :style="{
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '1px 0px',
      }"
    />
    <van-cell-group v-for="(item, index) in itemList" :key="index" :border="false">
      <van-cell title="订单号 : " :value="item.order_no" />
      <van-cell :title="item.item_name" :value="item.status_name" value-class="telcolor" />
      <van-cell :title="'已付 : ¥' + item.price" :value="'个数:' + item.count" />
      <van-cell
        title="收货地区 : "
        :value="item.province + '/' + item.city + '/' + item.area"
        v-if="item.is_address == 1"
      />
      <van-cell title="详细地址 : " :value="item.address" v-if="item.is_address == 1" />
      <van-cell title="备注信息 : " :value="item.notes" v-if="item.notes" class="notes" />
      <van-cell title="顺丰单号 : " :value="item.logistics_num" v-show="item.is_address == 1" />
      <van-cell title="时间 : " :value="item.created_at" />
<!--      <template v-if="item.order_other && item.order_other.length > 0">-->
<!--        <van-cell-->
<!--            class="pay-order-info"-->
<!--            v-for="(o, i) in item.order_other"-->
<!--            :key="i"-->
<!--            :title="item.order_other.length > 1 ? `购票人信息${i + 1}` : `购票人信息`"-->
<!--            :value="getPayOrderInfo(o)">-->
<!--        </van-cell>-->
<!--        <van-cell>-->
<!--          <template #default>-->
<!--            <p style="color: #f7863f">该入场券不会实体发货，凭购买通知短信入场。具体活动地址请等待短信提示。</p>-->
<!--          </template>-->
<!--        </van-cell>-->
<!--      </template>-->
      <van-cell title="请持二维码入场" class="qr-title" v-show="item.show_qr_code" />
      <van-cell v-show="item.show_qr_code" class="qr">
        <vue-qr
          :text="'https://sh.zhangcaijing.net?order_no=' + item.order_no"
          :margin="0"
        ></vue-qr>
      </van-cell>
      <van-divider
        :style="{
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '5px 0px',
        }"
      />
    </van-cell-group>
  </div>
</template>
<script>
import VueQr from "vue-qr";

export default {
  name: "user",
  components: {
    VueQr,
  },
  data() {
    return {
      phone: "",
      user_id: "",
      itemList: [],
    };
  },
  mounted() {
    // this.phone = this.$root.userInfo.phone
    this.user_id = this.$ls.get("user_id");
    this.getAllMyOrder();
  },
  methods: {
    async getAllMyOrder() {
      let res = await this.$axios.get("/item/getItemOrder", {
        params: {
          item_user_id: this.user_id,
        },
      });
      this.itemList = res.data;
    },
    getPayOrderInfo(o) {
      if(o.item_meeting_seat_no > 0) {
        return `${o.name} ${o.mobile} ${o.item_meeting_seat_no}座`;
      } else {
        return `${o.name} ${o.mobile}`;
      }
    }
  },
};
</script>
<style scoped>
.telcolor {
  color: #ee0a24;
}

.notes .van-cell__value,
.qr-title,
.qr .van-cell__value {
  text-align: center !important;
}

.qr-title {
  color: #ee0a24;
  font-weight: bold;
  letter-spacing: 0.06rem;
}

.qr-title .van-cell__title span {
  font-size: 1.25rem !important;
}

.pay-order-info .van-cell__value span {
  font-weight: bold;
  color: #000;
}
</style>
