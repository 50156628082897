<script>
  export default {
    props: ["goodsList", "teacher_id"],
    emits: ['imgClick', 'buyClick'],
    methods: {
      imgClick(item) {
        this.$emit("imgClick", item);
      },
      buyClick(item) {
        this.$emit("buyClick", item);
      }
    }
  }
</script>

<template>
  <div class="origin-good-item">
    <van-card
        v-for="(item, index) in goodsList"
        :key="index"
        :desc="item.desc"
        :title="item.name"
        :thumb="item.img_url"
        @click-thumb="imgClick(item)"
    >
      <template #tags>
        <van-tag
            v-show="item.show_inventories == 1"
            plain
            :type="item.inventories_num > 0 ? 'primary' : 'danger'"
        >{{ item.inventories_num > 0 ? "库存数量 : " + item.inventories_num : "已售罄" }}</van-tag
        >
      </template>
      <template #origin-price>
        <div v-show="item.original_price != 0">
          原价:{{ teacher_id == 100 ? item.original_price + " / 月" : item.original_price }}
        </div>
      </template>
      <template #price>
        <div>
          {{ teacher_id == 100 ? "限时秒杀" : "现价" }}:{{
            teacher_id == 100 ? item.price + " / 天" : item.price
          }}
        </div>
      </template>
      <template #footer>
        <div class="h-1"></div>
        <van-button
            @click="buyClick(item)"
            :color="item.inventories_num > 0 ? '#ee0a24' : '#666'"
            round
            :disabled="item.inventories_num == 0"
        >{{ item.buy_btn_str }}</van-button
        >
      </template>
    </van-card>
  </div>
</template>

<style scoped>

</style>