<template>
  <div class="w-full relative">
    <div class="w-full relative" v-if="teacher_id != 99999">
      <div class="info">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.big_img" @click="bannerClick(item)"/>
          </van-swipe-item>
        </van-swipe>
<!--        <p class="title justify-center items-center">{{ name }}</p>-->
        <!--      <p class="desc" v-if="desc">{{ desc }}</p>-->
<!--        <p class="desc-new">-->
<!--          <em>亲爱的粉丝朋友：</em>-->
<!--          <span>您好！衷心的感谢您一直以来对唯真财经平台的支持与厚爱，唯真财经始终秉承着“天下财经，唯真不破”的理念，为财经爱好者带来财经资讯、股市行情分析及实操战法。作为我们的优质用户和忠实粉丝，您的持续关注是我们最大的动力。为了回馈各位粉丝朋友，与大家近距离互动，我们将举办以“与唯真 赢未来”为主题的财富论坛暨粉丝见面会，届时，唯真财经各位金牌分析师将莅临现场与大家分享财富秘籍，共话未来趋势。更有“惊喜大礼”等你来拿，期待您的参加！</span>-->
<!--        </p>-->
      </div>
      <div v-show="goodsList.length == 0">
        <van-empty description="没有数据"/>
      </div>
      <OriginGoodItem v-if="teacher_id != 99999" :teacher_id="teacher_id" :goodsList="goodsList" @imgClick="imgClick"
                      @buyClick="buyClick"></OriginGoodItem>
      <div class="tip" v-if="teacher_id == 100 && goodsList.length > 0 && goodsList[0].tip">
        {{ "*" + goodsList[0].tip }}
      </div>
      <div v-if="teacher_id == 100">
        <van-image
            width="100%"
            height="100%"
            src="https://shengjin.oss-cn-hangzhou.aliyuncs.com/weizhen/common/course/desc/course20230213093019_9690.jpg"
        />
      </div>
      <div class="h-16"></div>
      <div class="use" v-if="teacher_id == 100">
        <van-button type="danger" round @click="use">点击观看课程</van-button>
        <!-- <van-image
          width="100%"
          height="100%"
          src="https://shengjin.oss-cn-hangzhou.aliyuncs.com/weizhen/common/item/item_ghp_limit_packages.png"
        /> -->
      </div>
    </div>
    <div v-else>
      <VipGoodItem :teacher_id="teacher_id" :goodsList="goodsList" @imgClick="imgClick" @buyClick="buyClick"></VipGoodItem>
    </div>
  </div>
</template>
<script>
import {ImagePreview, Image as VanImage} from "vant";
import OriginGoodItem from "@/components/OriginGoodItem.vue";
import VipGoodItem from "@/components/VipGoodItem.vue";

export default {
  name: "allgoods",
  components: {VipGoodItem, OriginGoodItem},
  data() {
    return {
      bannerList: [],
      name: "",
      desc: "",
      goodsList: [],
      teacher_id: new URLSearchParams(window.location.search).get("teacher_id") || 0,
    };
  },
  mounted() {
    let openid = this.$ls.get("user_openid");

    if (this.isWechat() && openid == null) {
      this.$router.push({
        name: "wxlogin",
        query: {
          teacher_id: this.teacher_id,
        },
      });
      this.$toast("微信登录");
    }

    this.getInfo();
    this.getAllgoods();
  },
  methods: {
    isWechat() {
      return /MicroMessenger/i.test(window.navigator.userAgent);
    },

    async getInfo() {
      let res = await this.$axios.get(
          `/system/getBookPromoteSaleAds?teacher_id=${this.teacher_id}`
      );
      if (res.code == 10001) {
        if (res.data && res.data.length > 0) {
          this.bannerList = res.data;
          this.name = res.data[0].name;
          this.desc = res.data[0].desc;
        }
      }
    },

    async getAllgoods() {
      let res = await this.$axios.get(`/item/getItemList?teacher_id=${this.teacher_id}`);
      this.goodsList = res.data;
    },

    buyClick(item) {
      switch (item.jump_type) {
        case 1:
          this.$router.push({
            name: "pay",
            query: {
              id: item.id,
              teacher_id: this.teacher_id,
            },
          });
          break;

        case 2:
          window.location.href = item.jump_param;
          break;

        default:
          Dialog.alert({
            title: "商品跳转参数异常",
          }).then(() => {
            window.location.href = "/";
          });
          break;
      }
    },

    imgClick(item) {
      ImagePreview({
        images: [item.img_url],
        closeable: true,
      });
    },

    bannerClick(item) {
      window.location.href = item.url;
    },

    use() {
      window.location.href = "https://wzcjmp.aigupiao8.com/teacher/2";
    },
  },
};
</script>
<style scoped>

.desc-new {
  padding: 0.63rem 1.25rem;
}

.desc-new em {
  font-style: normal;
}

.desc-new span, .desc-new em {
  display: block;
}

.desc-new span:last-child {
  text-indent: 32px;
}

.info {
  background-color: #fafafa;
  margin-bottom: 1.25rem;
}

.title {
  font-weight: 500;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem !important;
}

.title p {
  font-size: 1.8rem !important;
}

.title p:last-child {
  font-size: 1.4rem !important;
}

.desc {
  padding: 0.63rem 1.25rem;
  text-indent: 32px;
}

.van-card__title {
  font-size: 1rem;
  line-height: 1.25rem !important;
  max-height: fit-content;
}

.van-card__origin-price {
  margin-left: 0.63rem;
  text-decoration-color: #f40;
}

.van-card {
  margin-top: 0 !important;
  padding: 1rem;
}

.tip {
  padding: 1rem 1rem 0;
  text-align: center;
  font-size: 0.88rem !important;
  color: #f7863f;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  letter-spacing: 0.03rem;
  font-weight: 600;
}

.notice {
  padding: 1rem;
  overflow-y: auto;
}

.notice p {
  font-size: 1rem !important;
  color: #000;
  line-height: 2;
}

.notice p:last-child {
  margin-top: 0.5rem;
  text-align: center;
  color: #ee0a24;
  font-size: 1rem;
  font-weight: 600;
}

.use {
  width: inherit;
  text-align: center;
  position: fixed;
  bottom: 8.5vh;
}

.use .van-button {
  width: 90%;
  font-size: 1.13rem;
  font-weight: 900;
  letter-spacing: 0.3rem !important;
  opacity: 0.9;
}

@media screen and (min-width: 768px) {
  .notice {
    height: auto;
    overflow-y: auto;
  }

  .use {
    width: 50%;
  }
}
</style>
